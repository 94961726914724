:root {
  --color: black;
  --primary-color: #224261;
  --secondary-color: #E96055;
  --info-color: #66B8BE;
  --success-color: #74BE27;
  --warning-color: #F3BD55;
  --danger-color: #EF7869;
  --input-color: #f0f4ff;
  --cancel-color: #6F6F6F;
  --dirty-color: #fff8f1;
  --irrigation-border: #66b8be;
  --irrigation-mat-tab: #224261;
  --irrigation-table-header: #224261;
  --irrigation-pagination-numbers: #66b8be;
  --irrigation-accordion-bg: #f5fcff;
  --climate-border: #f3bd55;
  --climate-pagination-numbers: #f3bd55;
  --climate-accordion-bg: #fffbf2;
  --climate-zone-compartment: #E96055;
  --configurable-border: #224261;
  --configurable-bg: #22426130;
  --salmon: #FCEDE0;
  --menu-background: #C2E3E5;
  --menu-selected: #66B8BE
}

* {
  font-family: 'Cerebri Sans', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Cerebri Sans', sans-serif !important;
  font-weight: 500;
}

.h1, h1 {
  font-size: 1.625rem;
}

.h2, h2 {
  font-size: 1.125rem;
}

.h3, h3 {
  font-size: 0.84375rem;
}

.h4, h4 {
  font-size: .9375rem;
}

.h5, h5 {
  font-size: .8125rem;
}

.h6, h6 {
  font-size: .625rem;
}

.table-data-pr {
  padding-right: 0.2rem !important;
}

.table-data-pl {
  padding-left: 0.2rem !important;
}

ion-menu ion-item ion-label {
  font-family: 'Cerebri Sans', sans-serif !important;
}

button.accordion-button {
  font-weight: bold !important;
  color: var(--secondary-color) !important;
  background-color: inherit !important;
}

.accordion-item {
  border: 2px solid var(--irrigation-border);
  background-color: white;
  h2 {
    margin-top: 0;
  }
}

.accordion-title-div {
  width: 97%;
}

.form-check+.form-check {
  margin-top: 0;
}


.form-switch {
  /*.form-check-input {
    background-color: var(--danger-color);
  }*/
  .form-check-input:checked {
    background-color: var(--success-color);
    border-color: var(--success-color);
  }
  /*.form-check-input:focus:not(:checked) {
    background-color: var(--danger-color);
  }*/
}

/* To change accordion button change the FILL property between %23HEX_CODE'%3E */
/* bg color #E96055 */
.accordion-button:not(.collapsed)::after,
.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23E96055'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}

input[type="text"],
input[type="text"],
input[disabled].form-control,
input[type="number"],
.form-select,
.input-group-text {
  background-color: var(--input-color)
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.number-input {
  display: inline-flex;
}

.number-input,
.number-input * {
  box-sizing: border-box;
}

.number-input button {
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  border: none;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: inherit;
  cursor: pointer;
  margin: 0;
  position: relative;
  background-color: var(--input-color);
  color: var(--primary-color);
  transition: all 400ms;
}

.number-input button:before,
.number-input button:after {
  display: inline-block;
  position: absolute;
  content: '';
  width: 0.75rem;
  height: 2px;
  background-color: var(--secondary-color);
  color: var(--input-color);
  transform: translate(-50%, -50%);
}

.number-input button.plus:after {
  transform: translate(-50%, -50%) rotate(90deg);
}

.number-input button:hover {
  background-color: lightgray;
}

.number-input button.minus {
  border-bottom-left-radius: 0.357rem !important;
  border-top-left-radius: 0.357rem !important;
}

.number-input button.border-plus {
  border-bottom-right-radius: 0.357rem;
  border-top-right-radius: 0.357rem;
}

.number-input input[type=number] {
  font-family: sans-serif;
  text-align: center;
  height: inherit;
  padding-left: 0 !important;
}

select.form-select:focus {
  // border: none !important;
  border-color: #224261;
}

input[type="text"]:focus,
input[type="number"]:focus {
  border: none;
}

input.form-control:focus {
  background-color: var(--input-color);
}

/* bg color #E96055 */
.form-select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m1 1 3.25 3.25L7.5 1' stroke='%23E96055' stroke-width='1.083' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

/*
.accordion-item:first-of-type {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
 */

.fertilizers-installer {
   .ngx-pagination {
    text-align: center;
    padding-left: 0;
     font-size: 13px;
  }

   .ngx-pagination .current {
    background: #224261;
  }
}

.irrigation-table {
  .search-container > input[type='text'],
  .input-group-text {
    background-color: white;
  }

  .row-pointer {
    cursor: pointer;
    transition: all 500ms;
  }

  .card-body .ngx-pagination {
    text-align: center;
    padding-left: 0;
  }

  .card-body .ngx-pagination .current {
    border-radius: 5px;
    background: var(--irrigation-pagination-numbers);
  }

  .search-icon {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    color: var(--secondary-color);
  }

  .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--secondary-color);
    opacity: 1; /* Firefox */
  }

  .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--secondary-color);
  }

  .form-control::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--secondary-color);
  }
}

.number-input button.minus.minus-style {
  margin-right: 0.3rem !important;
  border-bottom-right-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

.number-input button.plus.plus-style {
  margin-left: 0.3rem !important;
  border-radius: 0.375rem !important;
}

.input-group-text {
  margin-left: 0.3rem !important;
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

input.form-control {
  border-radius: 0.375rem !important;
}

.irrigation-card-border {
  border: 2px solid var(--irrigation-border);
}

.climate-card-border {
  border: 2px solid var(--climate-border);
  border-radius: 0.375rem;
}

.configurable-card-border {
  border: 2px solid var(--configurable-border);
  border-radius: 0.375rem;
}

.row-pointer {
  cursor: pointer;
  transition: all 500ms;
}

div.climate-accordion-body {
  padding: 0;
  background-color: transparent;
  border-radius: 0.375rem;
}

.climate-select {
  background-color: #fff;
}

.zone-bg-color,
.climate-card-border .accordion-header {
  background-color: var(--climate-accordion-bg);
  border-radius: 0.375rem;
}

.advanced-form {
  .mb-05 {
    margin-bottom: 0.5rem !important;
  }
  h6.fs-2 {
    margin: 0;
  }
  h6.fs-5 {
    margin-bottom: 0.5rem;
    margin-top: 0.875rem;
  }
  h6.fs-2.input-label {
    margin-bottom: 0.5rem;
  }
  .inline-label {
    line-height: 38.5px;
  }

  .card-body {
    padding: 0.75rem 1.25rem;
  }
  .card {
    margin-bottom: 0.5rem;
  }

  input {
    padding-right: 0 !important;
  }

  input.bs-timepicker-field {
    padding-left: 0 !important;
  }
}

.breadcrumb-nav-row {
  padding: 0.5rem 1.25rem;
}

.breadcrumb-col {
  padding-left: 0;
}

.simple-form {
  h6.fs-2 {
    margin-top: 0;
  }

  h6.fs-5 {
    margin-bottom: 0.5rem;
    margin-top: 0.875rem;
  }

  .card-body {
    padding: 1.25rem;

    p {
      margin-bottom: .375rem;
    }
  }

  .fw-lighter {
    color: #5F5F5F;
  }

  input {
    padding-right: 0 !important;
  }

  input.bs-timepicker-field {
    padding-left: 0 !important;
  }
}

.climate-table {
  border: none;
  margin-bottom: 0;

  .advanced-form td,
  .simple-form td {
    padding-bottom: 0.4rem;
    padding-top: 0.4rem;

    .input-time-padding {
      padding-left: 0;
    }
  }

  /* TABLE THEAD (PERIODS) STICKY */
  .table-responsive {} /* DON'T DELETE THIS LINE */

  .table-large {
    @extend .table-responsive;
    height: 700px;
    overflow: scroll;
  }

  .table-small {
    @extend .table-responsive;
    height: 500px;
    overflow: scroll;
  }

  thead tr:nth-child(1) th {
    background-color: #f9fbfd;
    position: sticky;
    color: #95aac9;
    font-weight: 600;
    letter-spacing: .08em;
    font-size: .8125rem;
    text-transform: uppercase;
    top: 0;
    z-index: 10;
  }
  /* TABLE THEAD (PERIODS) STICKY */

  table {
    margin-bottom: 0;
  }

  .table-border-bottom {
    border-bottom: 1px solid #edf2f9;
  }

  .table-border-right {
    border-right: 1px solid #edf2f9;
  }

  tbody td {
    border-bottom: 1px solid transparent;
  }

  .remove-border-top {
    border-top: none;
  }

  tbody > tr {
    border-bottom: none;
  }

  .table-border-right:last-child {
    border-right: none;
  }

  tr > th {
    border-top: none;
  }

  .climate-simple-titles {
    margin: 0;
    padding: 0;
  }

  .remove-border > td {
    border: none;
  }

  /* Avoid radio buttons to show white dot on dirty input */
  .form-check-input:checked[type=radio]{
    background-color: black;
  }

  .search-container > input[type='text'],
  .input-group-text {
    background-color: white;
  }

  /*
  .table.table-outside-border{
    border: 1px solid var(--climate-border) !important;
    border-radius: 0.375rem;
  }
   */

  .number-input{
    min-width: 130px;
  }

  .row-pointer {
    cursor: pointer;
    transition: all 500ms;
  }

  .card-body .ngx-pagination {
    text-align: center;
    padding-left: 0;
  }

  .icon-style {
    font-size: 1.2rem;
    color: deepskyblue;
    text-align: right;
  }

  .humidity-icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 3rem;
  }

  .card-body .ngx-pagination .current {
    border-radius: 5px;
    background: var(--climate-pagination-numbers);
  }

  .search-icon {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    color: var(--secondary-color);
  }

  .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--secondary-color);
    opacity: 1; /* Firefox */
  }

  .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--secondary-color);
  }

  .form-control::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--secondary-color);
  }

  .form-switch {
    margin-left: auto;
    margin-right: auto;
  }

  .climate-modal-container tr td {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
  }
}
/*
.card.card-no-border {
  border: 0;
}
 */

@media (min-width: 576px){
  .simple-form {
    .col-inputs {
      .first-row {
        h6.fs-5 {
          margin-top: 0;
        }
      }
    }
  }
}

.climate-modal-container {
  input.form-control.ng-dirty {
    background-color: var(--dirty-color);
  }
}

ion-button.isModalButtonDirty {
  --background: var(--dirty-color);
  color: black;
}

.advanced-form h6.fs-5, .simple-form h6.fs-5 {
  font-weight: normal;
}
.advanced-form, .simple-form {



  input.ng-dirty:not(.form-check-input), select.ng-dirty {
    background-color: var(--dirty-color);
  }
  input.ng-dirty.mat-input-element {
    /* TODO revisar para que todo el componente se ponga rojo */
    background: transparent;
  }

  /* For example: #cc2222 fill='%23cc2222'/%3e%3c/svg%3e"*/
  .form-check-input.ng-dirty {
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='red'/%3e%3c/svg%3e"); */
    outline-offset: 3px;
    outline: 2px dashed red;
  }

  .popover {
    max-width: none;
  }
  .form-control:not(.form-select) {
    padding: .375rem .55rem;
  }

}

.irrigation-accordion {
  .accordion-body {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.accordion-body {
  background-color: var(--irrigation-accordion-bg);
}

.bg-success {
  background-color: var(--success-color) !important;
}
.bg-danger {
  background-color: var(--danger-color) !important;
}

button.swal2-styled.swal2-confirm {
  background-color: var(--primary-color);
}
button.swal2-styled.swal2-cancel {
  background-color: var(--cancel-color);
}
button.swal2-styled.swal2-deny {
  background-color: var(--danger-color);
}
button.swal2-styled.swal2-default-outline:focus, button.swal2-styled.swal2-confirm:focus {
  box-shadow: none;
}

.custom-chip-list {
  width: 100%;
  mat-chip {
    /* background-color: var(--info-color) !important; */
  }
}

@media (max-width: 820px){
  .number-input button:before,
  .number-input button:after {
    width: 0.5rem;
    height: 1px;
  }

  .input-group-prepend span {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
  }

  .custom-container-fluid {
    padding-right: 0.4rem;
    padding-left: 0.4rem;
  }

  .advanced-form {
    .custom-card-body {
      padding: 0.75rem;
    }
  }

  .simple-form {
    .custom-card-body {
      padding: 0.75rem;
    }
  }

  .custom-accordion-body {
    padding: 0.7rem;
  }

  .accordion-custom-title {
    font-size: 1rem;
  }

  .zone-bg-color {
    .select-zone-compartment {
      font-size: 1.1rem;
    }
  }

  .mat-menu-content {
    .app-mode-copy,
    .app-mode-edition {
      display: none;
    }
  }
}

@media (max-width: 480px) {
  .terminal-table-header {
    color: var(--irrigation-table-header);
    font-size: 0.5rem !important;
    padding: 0.5rem !important;
  }

  .terminal-table-data {
    font-size: 0.8rem !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .table-search-text {
    font-size: 0.8rem;
  }

  .ngx-pagination {
    font-size: 0.8rem !important;
  }

  .mat-tab-label-content {
    font-size: 0.7rem;
  }

  .mat-tab-labels div {
    padding: 0.5rem;
  }

  .mat-tab-header button {
    font-size: 0.4rem;
  }
}

.mode-container {
  margin-right: 0.0001rem;
}

/*
.btn-danger {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}
*/

.compartment-sticky {
  position: sticky;
  top: -2px;
  z-index: 1020;
}

h2.accordion-header {
  position: sticky;
  top: 0;
  z-index: 999;
  background: var(--irrigation-accordion-bg);
  border-radius: 0.375rem;

  .accordion-button:not(.collapsed) {
    box-shadow: inset 0 -1px 0 rgba(18,38,63,.125);
  }
}

h2.accordion-header.climate-accordion-header {
  position: sticky;
  top: 90px;
  z-index: 999;
  background: var(--climate-accordion-bg);
  border-radius: 0.375rem;

  .accordion-button:not(.collapsed) {
    box-shadow: inset 0 -1px 0 rgba(18,38,63,.125);
  }
}

.select-zone-compartment {
  font-weight: bold;
  font-size: 1.25rem;
  color: var(--climate-zone-compartment);
}

.terminal-grid {
  .nav-tabs .nav-link {
    padding: 1rem;
  }

  ::ng-deep .mat-tab-label-content {
    color: var(--irrigation-mat-tab);
  }

  .tab-container {
    min-height: 400px;
  }

  .data-container {
    background-color: white;
    padding: 0.75rem;
    border-radius: 10px;
    min-height: 100%;
  }

  .data-header {
    color: var(--primary-color);
    font-size: 0.6rem;
    font-weight: bold;
    height: auto
  }

  .data-value {
    font-weight: bold;
    font-size: 1.5rem;
  }

  .data-icon {
    text-align: right;
    font-size: 1.5rem;
    color: var(--secondary-color);
  }

  .head-button {
    text-align: right;
  }

  .terminal-title {
    color: var(--secondary-color);
    font-size: 0.8rem;
    margin-bottom: 0.1rem;
  }

  .terminal-header {
    font-weight: bold;
    font-size: 1.7rem;
    margin-bottom: 0.4rem;
  }

  .terminal-space {
    margin-bottom: 0.4rem;
  }

  .terminal-text {
    color: #151414;
    font-size: 1rem;
  }

  .terminal-image {
    border-radius: 10px;
  }

  .custom-row {
    display: flex;
    justify-content: space-between;
  }

  ::ng-deep .mat-tab-body-content {
    overflow: hidden !important;
  }
  /* ink bar */
  ::ng-deep .mat-ink-bar {
    background-color: #2f4f99 !important;
  }

  @media (max-width: 995px) {
    .data-header {
      font-size: 0.6rem;
    }

    .data-value {
      font-size: 1rem;
    }

    .data-icon {
      font-size: 1.2rem;
    }

    .data-container {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      padding-inline: 0.75rem;
    }
  }

  @media (max-width: 810px) {
    .nav-tabs {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 1rem;
    }
  }
}

.right-switch {
  margin-left: auto;
}

.drag-drop-border {
  outline: 2px dashed lightblue;
  outline-offset: 5px;
  border-radius: 5px;
}

/* CUSTOM TIMEPICKER WITHOUT HOURS */
#transformGroupMinPulseSimpleId,
#transformGroupMinPulseAdvanceId,
#transformGroupValveTimeSimpleId,
#transformGroupValveTimeAdvanceId,
#transformGroupDelayTimeAdvanceId,
#transformGroupDelayTimeSimpleId,
#transformGroupLoopTimeSimpleId,
#transformGroupLoopTimeAdvanceId,
#alarmsFormCommonONDelaySimpleId,
#alarmsFormCommonONDelayAdvanceId,
#alarmsFormONDelayAdvanceId,
#alarmsFormMinCO2ONDelaySimpleId,
#alarmsFormMinCO2ONDelayAdvanceId,
#alarmsFormMaxCO2ONDelayAdvanceId,
#alarmsFormMaxCO2ONDelaySimpleId,
#alarmsFormMaxCO2OFFDelayAdvanceId,
#alarmsFormMaxCO2OFFDelaySimpleId,
#alarmsFormMinCO2OFFDelaySimpleId,
#alarmsFormMinCO2OFFDelayAdvanceId,
#alarmsFrostONDelaySimpleId,
#alarmsFrostONDelayAdvanceId,
#alarmsFrostOFFDelayAdvanceId,
#alarmsFrostOFFDelaySimpleId,
#alarmsStormONDelayAdvanceId,
#alarmsStormONDelaySimpleId,
#alarmsStormOFFDelaySimpleId,
#alarmsStormOFFDelayAdvanceId,
#alarmsRainONDelaySimpleId,
#alarmsRainONDelayAdvanceId,
#alarmsRainOFFDelaySimpleId,
#alarmsRainOFFDelayAdvanceId,
#installerVentsRuntimeId,
#installerVentsPrefunctionTimeId,
#installerMixedRunTimeId,
#installerMixedRadiationDelayId,
#installerMixedSecurityTimeId,
#installerMixedPrefunctionTimeId,
#installerDarkeningRadiationDelayId,
#installerDarkeningRunTimeId,
#installerDarkeningSecurityTimeId,
#installerDarkeningPrefunctionTimeId,
#installerShading1RunTimeId,
#installerShading2RunTimeId,
#installerShading1RadiationDelayId,
#installerShading2RadiationDelayId,
#installerShading1SecurityTimeId,
#installerShading2SecurityTimeId,
#installerShading1PrefunctionTimeId,
#installerShading2PrefunctionTimeId {
  tr td:first-child,
  tr td:nth-child(2) {
    display: none;
  }
}

.ion-page {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.installer-edit-button {
  cursor: pointer;
  font-size: 1.3rem;
  color: blue;
  transition: all 500ms;

  &:hover {
    color: dodgerblue;
  }
}

.connected-container {
  margin-bottom: 5rem;
}

.mat-badge-content {
  color: white;
  background: var(--secondary-color);
}

.input-group--custom {
  width: 70%;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.text-secondary {
  color: var(--secondary-color) !important;
}

.text-right {
  text-align: right !important;
}

.bg-salmon {
  background-color: var(--salmon) !important;
}

.card-mat-expansion-panel {
  border-radius: 0.5rem !important;
  box-shadow: 0 .75rem 1.5rem rgba(18,38,63,.03) !important;
}
