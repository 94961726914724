/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@import 'swiper/swiper.min.css';
@import 'swiper/modules/navigation/navigation.min.css';
@import 'swiper/modules/pagination/pagination.min.css';
/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

/* NTC 360 */
@import './theme/common';

@import '~bootstrap-icons/font/bootstrap-icons.css';

/**
  Mobile native apps status bar: to be improved in iOS dark mode
 */
.safe--area {
  padding-top: env(safe-area-inset-top);
}
ion-app {
  margin-top: env(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
}
ion-header ion-toolbar:first-of-type {
  padding-top: 0px !important;
}
.plt-ipad body {
  margin-top: 30px !important;
}

/**
  Dark mode behaviour
 */
:root {
  --content-background-color: #f2f5fc;
  /* Disable dark mode */
  color-scheme: light only;
}
ion-content {
  --ion-background-color: var(--content-background-color);
}
/*ion-content {
  --background: blue;
}

body {
  background: blue;
}*/
.input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-control,
.form-select,
.input-group-text {
  // border: transparent;
  background-color: var(--input-color);
}

.form-control:focus,
.form-select:focus {
  // border: 1px solid #d2ddec;
  border-color: #224261;
}

.custom-multiselect::part(icon),
.custom-select::part(icon) {
  display: none;
}

/* Ion select scrollbar */
.alert-radio-group::-webkit-scrollbar,
.alert-checkbox-group::-webkit-scrollbar {
  width: 1em;
  display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track,
.alert-checkbox-group::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.alert-radio-group::-webkit-scrollbar-thumb,
.alert-checkbox-group::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-clip: padding-box;
}

/* Ion select scrollbar */

.custom-multiselect-alert .alert-wrapper {
  border-radius: 0.375rem;
  .alert-head {
    display: none;
  }
  .alert-checkbox-group {
    padding-top: 15px;
    border-top: 0;
    border-bottom: 0;
  }
}

.custom-select-alert .alert-wrapper {
  border-radius: 0.375rem;
  .alert-head {
    display: none;
  }
  .alert-radio-group {
    padding-top: 15px;
    border-top: 0;
  }
}

.custom-autocomplete-form {
  width: 100%;
  .autocomplete-full-width {
    width: 100%;
  }
}

ion-modal.auto-height {
  &.bottom {
    align-items: flex-end;
  }

  --height: auto;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      padding-bottom: 20px;
      max-height: 90vh;
      overflow: auto;
    }
  }
}

.ion-page {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.text-muted {
  line-height: 1;
}
